.data-table {
  width: 100%;

  table {
    border-collapse: collapse;
  }

  tr:nth-child(even) {
    background-color: $color-lightgray;
  }

  tr:hover {
    background-color: $color-hover-blue;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: $color-lightblue;
  }

  th, td {
    border: 1px solid $color-lightblue;
    padding: 8px;
    color: $color-darkgray;
    text-decoration: none;
  }

  td.money {
    text-align: right;
  }
}

.bigInputForSearchingOrFiltering {
  background-position: 10px 12px; /* Position the search icon */
  background-repeat: no-repeat; /* Do not repeat the icon image */
  width: 100%; /* Full-width */
  font-size: 16px; /* Increase font-size */
  padding: 12px 20px 12px 20px; /* Add some padding */
  border: 1px solid #ddd; /* Add a grey border */
  margin-bottom: 12px; /* Add some space below the input */
}

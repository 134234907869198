.toggle {
  margin-bottom: 0;

  > dd {
    @include font-size(sm);

    padding-bottom: $grid-gutter-width / 2;
    padding-left: $grid-gutter-width;
  }

  > dt > a {
    @include font-size(md);

    position: relative;

    @include icon;

    @extend .icon-wew-close-small;

    @include icon-size(sm);

    &:before {
      @include trans;

      position: absolute;
      transform: rotate(-45deg);
      left: -$grid-gutter-width;
    }

    &:active, &:focus {
      color: $color-blue;
      text-decoration: none;
    }

    color: $color-blue;

    &:hover, &.active {
      color: $color-darkblue;
      text-decoration: none;
    }

    &.active {
      &:before {
        transform: rotate(0deg);
      }
    }
  }

  > dt {
    margin-top: $grid-gutter-width / 2;
    padding-bottom: $grid-gutter-width / 2;
    padding-left: $grid-gutter-width;
  }

  &.toggle-table {
    margin-bottom: $grid-gutter-width;
    border-bottom: 2px solid $color-gray;

    > dt > a {
      border-top: 2px solid $color-gray;
      display: block;
      padding-top: 20px;

      @include font-size(lg);

      @include icon-size(sm);

      &:before {
        right: 0;
        left: auto;
        top: 22px;
      }
    }

    > dt {
      margin-top: 5px;
      padding-left: 0;
    }

    > dd {
      padding-left: 0;
      padding-bottom: 0;
    }
  }
}
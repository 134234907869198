/* ==========================================================================
   Form
   ========================================================================== */
.card-desc + form {
  margin-top: $grid-gutter-width;
  margin-bottom: 100px - $grid-gutter-width * 2;
}

.no-card-footer .card-desc + form {
  margin-bottom: 0;
}

.card form {
  .alert {
    display: none;

    @include font-size(xs);

    margin-bottom: 0;
    padding-bottom: 0;
    font-weight: 00;
  }

  .form-control.error {
    border-color: transparent;
  }

  // Form Footer
  .form-footer {
    background-color: $color-white;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 100px - $grid-gutter-width;
    padding: 0 $grid-gutter-width $grid-gutter-width;
  }
}

.card-edit {
  .card-desc + form {
    margin-bottom: 100px + $grid-gutter-width;
  }

  form.bottom-margin {
    margin-bottom: 100px + $grid-gutter-width;
  }

  form {
    .alert {
      display: block;
    }

    .form-footer {
      background-color: $color-lightblue;
      min-height: 100px;
      padding: $grid-gutter-width;
    }

    .error .form-control {
      border-color: $color-red;
    }

    .success, .error {
      .form-line {
        position: relative;

        @include icon;

        @include icon-size;

        @extend .icon-wew-check-circle;

        &:before {
          position: absolute;
          bottom: 13px;
          right: -$grid-gutter-width - 10px;
          color: $color-blue;
        }
      }
    }

    .error .form-line:before {
      @extend .icon-wew-warning;

      color: $color-red;
    }
  }
}

.form-group {
  margin-right: $grid-gutter-width + 10px;
  margin-bottom: $grid-gutter-width;

  &.disabled {
    margin-right: 0;
    margin-bottom: $grid-gutter-width;
  }

  label {
    @include font-size(sm);

    color: $color-darkblue;
  }
}

.static_label_container {
  margin-right: $grid-gutter-width + 10px;

  &.disabled {
    margin-right: 0;
  }

  label {
    @include font-size(sm);

    color: $color-darkblue;
  }
}

.checkbox {
  label {
    color: $color-black;
  }
}

.form-control {
  box-shadow: none;

  @include font-size(md);

  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 2px;
  border-left-width: 0px;
  padding-left: $grid-gutter-width / 2;
  padding-right: $grid-gutter-width / 2;

  &:focus {
    box-shadow: none;
  }

  &[disabled],
  &[readonly],
  fieldset[disabled] {
    color: $color-darkgray;
    cursor: auto;
  }
}

.radio-tabs {
  width: 100%;

  .btn {
    border: none;
    border-radius: 0;
    margin-left: 0 !important;
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
  }

  .btn-primary {
    background-color: $color-gray;

    &:hover {
      background-color: darken($color-gray, 10%);
    }

    &:active, &:focus {
      background-color: darken($color-gray, 17%);
    }
  }

  .btn-primary.active {
    background-color: $color-darkblue;
  }
}

.svg select.form-control {
  appearance: none;
  width: 100%;
  background-image: url("../img/icons/wew-arrow-down.svg");
  background-position: right;
  background-repeat: no-repeat;
}

.svg select.form-control:disabled {
  background-image: none;
}

/* hide browser-styling (arrow) in IE10 */
select::-ms-expand {
  display: none;
}

html .form-control::-webkit-input-placeholder {
  color: $color-gray;
}

html .form-control:-moz-placeholder {
  color: $color-gray;
}

html .form-control::-moz-placeholder {
  color: $color-gray;
}

html .form-control:-ms-input-placeholder {
  color: $color-gray;
}

/* ==========================================================================
   Reset
   ========================================================================== */
@import normalize

/* ==========================================================================
   Setup
   ========================================================================== */
@import "colors"
// WeWash Colors
@import "variables"
// Bootstrap overrides
@import "fonts"
// WeWash Colors
@import "bootstrap"
// Init Bootstrap
@import "bootstrap-nonresponsive"
// Remove responsive behaviours
@import "main"
// Magnific Popup

/* ==========================================================================
   Plugins
   ========================================================================== */
@import "plugins/plugins"

/* ==========================================================================
   Start
   ========================================================================== */
@import "material"
@import "helper"
@import "type"
// WeWash Typography

/* Layout
   ========================================================================== */
@import "helper"
@import "general"
@import "navigation"
@import "breadcrumb"
@import "card"
@import "buttons"
@import "tooltip"
@import "forms"
@import "tables"
@import "toggle"
@import "section-info"
@import "section-content"
@import "section-contentinfo"
@import "footer"
@import "column-fix"
@import "turnover-usage"
@import "appliance"

@import "../src/app/components/locations/forms/location-position-form"
// @import "debug"

@import "wewash"

@charset "UTF-8";

/*========================================
=            WeWash Icon Font            =
========================================*/

@mixin icon {
  color: $color-darkblue;

  &:before {
    font-family: "wewash" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.icon {
  @include icon;
}

@mixin icon-size($size: sm) {
  @if $size == xs {
    &:before {
      font-size: $icon-size / 2;

      // line-height: $line-height / 1.25;
      // font-weight: 300;
      vertical-align: middle;
    }
  } @else if $size == sm {
    &:before {
      font-size: $icon-size;

      // line-height: $line-height;
      // font-weight: 100;
      vertical-align: middle;
    }
  } @else if $size == md {
    &:before {
      font-size: $icon-size * 2;

      // line-height: $line-height;
      // font-weight: 300;
      vertical-align: middle;
    }
  } @else if $size == lg {
    &:before {
      font-size: $icon-size * 3;

      // line-height: $line-height * 0.75;
      // font-weight: 300;
      vertical-align: middle;
    }
  } @else if $size == xl {
    &:before {
      font-size: $icon-size * 4;

      // line-height: $line-height;
      // font-weight: 300;
      vertical-align: middle;
    }
  } @else {
    &:before {
      font-size: $icon-size;

      // line-height: $line-height;
      // font-weight: 300;
      vertical-align: middle;
    }
  }
}

.icon-xs {
  @include icon-size(xs);
}

.icon-sm {
  @include icon-size(sm);
}

.icon-md {
  @include icon-size(md);
}

.icon-lg {
  @include icon-size(lg);
}

.icon-xl {
  @include icon-size(xl);
}

/*----------  Fontastic File  ----------*/
@font-face {
  font-family: "wewash";
  src: url("../fonts/wewash.eot");
  src: url("../fonts/wewash.eot?#iefix") format("embedded-opentype"), url("../fonts/wewash.woff") format("woff"), url("../fonts/wewash.ttf") format("truetype"), url("../wewash.svg#wewash") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "wewash" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "wewash" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-wew-admin-add:before {
  content: "\61";
}

.icon-wew-admin-add-large:before {
  content: "\62";
}

.icon-wew-admin-id:before {
  content: "\63";
}

.icon-wew-admin-usage-data:before {
  content: "\43";
}

.icon-wew-admin-managed:before {
  content: "\64";
}

.icon-wew-admin-setting:before {
  content: "\65";
}

.icon-wew-arrow-down:before {
  content: "\66";
}

.icon-wew-arrow-left:before {
  content: "\67";
}

.icon-wew-arrow-right:before {
  content: "\68";
}

.icon-wew-arrow-right-small:before {
  content: "\69";
}

.icon-wew-bill:before {
  content: "\6a";
}

.icon-wew-bill-download:before {
  content: "\6b";
}

.icon-wew-button-enter:before {
  content: "\6c";
}

.icon-wew-calendar-euro:before {
  content: "\6d";
}

.icon-wew-calendar-euro-large:before {
  content: "\6e";
}

.icon-wew-check-circle:before {
  content: "\6f";
}

.icon-wew-circle-add:before {
  content: "\70";
}

.icon-wew-circle-notification:before {
  content: "\71";
}

.icon-wew-circle-subtract:before {
  content: "\72";
}

.icon-wew-close:before {
  content: "\73";
}

.icon-wew-close-small:before {
  content: "\74";
}

.icon-wew-dryer:before {
  content: "\75";
}

.icon-wew-edit:before {
  content: "\76";
}

.icon-wew-house-add:before {
  content: "\77";
}

.icon-wew-house-add-large:before {
  content: "\78";
}

.icon-wew-operational-time:before {
  content: "\79";
}

.icon-wew-property:before {
  content: "\7a";
}

.icon-wew-property-managed:before {
  content: "\41";
}

.icon-wew-retrofit-add:before {
  content: "\42";
}

.icon-wew-retrofit-check:before {
  content: "\43";
}

.icon-wew-retrofit-connect:before {
  content: "\44";
}

.icon-wew-retrofit-connect-large:before {
  content: "\45";
}

.icon-wew-retrofit-shopping:before {
  content: "\46";
}

.icon-wew-retrofit-shopping-large:before {
  content: "\47";
}

.icon-wew-retrofit-warning:before {
  content: "\48";
}

.icon-wew-setting:before {
  content: "\49";
}

.icon-wew-trash:before {
  content: "\4a";
}

.icon-wew-users:before {
  content: "\4b";
}

.icon-wew-users-large:before {
  content: "\4c";
}

.icon-wew-warning:before {
  content: "\4d";
}

.icon-wew-warning-large:before {
  content: "\4e";
}

.icon-wew-washmachine:before {
  content: "\4f";
}

.icon-wew-washmachine-add-large:before {
  content: "\50";
}

.icon-wew-washmachine-large:before {
  content: "\51";
}

.icon-wew-washroom:before {
  content: "\52";
}

.icon-wew-washroom-add:before {
  content: "\53";
}

.icon-wew-washroom-add-large:before {
  content: "\54";
}

.icon-wew-washroom-large:before {
  content: "\55";
}

.icon-wew-washroom-managed:before {
  content: "\56";
}

.icon-wew-arrow-left-small:before {
  content: "\57";
}

.icon-wew-dryer-large:before {
  content: "\58";
}

.year-wrapper {

  padding: 0;
  margin-bottom: 0;

  &:first-of-type {
    margin-top: 25px;
  }

  .info-message {
    text-align: center;
    margin-top: 50px;
  }

  li {
    list-style: none;
  }

  .year {
    &.active {
      .title {
        background-color: $color-lightgray;
        color: $color-black;
      }

      .month-wrapper {
        display: table;
        width: 100%;

        .col {
          display: table-cell;
          width: 50%;
        }
      }

      .active-indicator {
        transform: rotate(90deg);
      }
    }

    .loading {
      margin: 10px;
      text-align: center;
    }

    .month-wrapper {
      display: none;
      list-style-type: none;
      color: $color-gray;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .title {
      height: $card-footer-height - 12;
      display: block;
      border-top: 1px solid $color-lightgray;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 5px;
      color: $color-gray;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      span {
        margin-left: 5px;
        font-size: 1.2em;
        vertical-align: middle;
      }

      .icon {
        display: inline-block;
      }

      &:hover {
        background-color: $color-lightgray;
        color: $color-black;
        cursor: pointer;
        cursor: hand;
      }
    }
  }

}

.download-link,
.clickable-header {
  cursor: pointer;
}

.width-limited-15 {
  max-width: 15em;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}


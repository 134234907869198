.nopr {
  padding-right: 0 !important;
}

.noprr > :first-child{
  padding-right: 0 !important;
}

.spacer {
  margin-top: $grid-gutter-width - 5px;
}

.spacer-x-2 {
  margin-top: ($grid-gutter-width - 5px) * 2;
}

.spacer-x-3 {
  margin-top: ($grid-gutter-width - 5px) * 3;
}

.spacer-x-4 {
  margin-top: ($grid-gutter-width - 5px) * 4;
}

.spacer-tb {
  margin-top: $grid-gutter-width - 5px;
  margin-bottom: $grid-gutter-width - 5px;
}

.spacer-b {
  margin-bottom: $grid-gutter-width - 5px;
}

.divider-lr {
  border-left: 2px solid $color-lightgray;
  border-right: 2px solid $color-lightgray;
}

.divider-l {
  border-left: 2px solid $color-lightgray;
}

.divider-r {
  border-right: 2px solid $color-lightgray;
}

.border-top {
  border-top: 1px solid $color-lightgray;
}

$types: 0;
$type-width: 0px;
$typeLoop: 210;

@while $types < $typeLoop {
  .mt-#{$types} {
    margin-top: ($type-width + $types) !important;
  }

  .mb-#{$types} {
    margin-bottom: ($type-width + $types) !important;
  }

  .ml-#{$types} {
    margin-left: ($type-width + $types) !important;
  }

  .mr-#{$types} {
    margin-right: ($type-width + $types) !important;
  }

  .pt-#{$types} {
    padding-top: ($type-width + $types) !important;
  }

  .pb-#{$types} {
    padding-bottom: ($type-width + $types) !important;
  }

  $types: $types + 10;
}

@mixin level($level: 0) {
  @if $level == 1 {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }
  @else if $level == 2 {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  @else if $level == 3 {
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  }
  @else if $level == 4 {
    box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
  }
  @else if $level == 5 {
    box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
  }
  @else {
    box-shadow: none !important;
  }
}

.level-1,
.level-2,
.level-3,
.level-4,
.level-5 {
  position: relative;
  z-index: 1;
}

.level-0 {
  @include level;
}

.level-1 {
  @include level(1);
}

// .level-1-half {
//   box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
// }

.level-2 {
  @include level(2);
}

.level-3 {
  @include level(3);
}

.level-4 {
  @include level(4);
}
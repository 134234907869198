.controlTypeOption {
  display: flex;
  position: relative;
}

.controlTypeGroupTitle {
  margin-top: 10px;
  color: $color-blue;
}

.controlTypeLabel {
  margin-left: 5px;
  color: $color-black !important;
}

.controlTypeTooltipIcon {
  margin-left: 10px;
  font-weight: bold;
  font-size: 12px;
  color: $color-white;
  background-color: $color-blue;
  border-radius: 50%;
  padding: 1px 4px;
  cursor: pointer;
}

.controlTypeTooltipText {
  visibility: hidden;
  color: $color-black;
  background-color: $color-lightblue;
  padding: 20px 0;
  position: absolute;
  z-index: 1;
  right: 0;
}

.controlTypeTooltip:hover .controlTypeTooltipText {
  visibility: visible;
}

/* ==========================================================================
   General
   ========================================================================== */
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  @include font-size(sm);

  color: $color-black;
}

/* ==========================================================================
   Navigation
   ========================================================================== */
.navigation {
  @include font-size(sm);
}

/* ==========================================================================
   Headlines
   ========================================================================== */
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  line-height: 1;
  font-size: $font-size;
  font-weight: normal;
}

.headline1 {
  @include font-size(lg);

  color: $color-darkblue;

  small {
    color: $color-darkblue;
  }
}

.headline2 {
  @include font-size(lg);

  color: $color-black;
}

.headline3 {
}

.headline4 {
}

.headline5 {
}

.headline1 {
  .first-item {
    &.truncate {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  span {
    &.truncate {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.headline1, .headline2, .headline3, .headline4, .headline5, .headline6 {
  span {
    display: block;
    color: $color-blue;
  }
}

.tel, .email {
  @include font-size(md);

  font-weight: 400;
}

.font-md {
  @include font-size(md);
}

.font-xxl {
  @include font-size(xxl);
}

.location-position-form {
  .map-wrapper {
    position: relative;
    .disabled-overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 3;
    }
    .pin {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100px;
      height: 100px;
      transform: translate(-50%, -100%);
      background-image: url(/img/map_pin.png);
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 2;
    }
  }
}

.site-wrapper {
  background-color: $color-lightgray;

  // width: $screen-lg;
  // margin: 0 auto;
  position: relative;
}

.site-wrapper.admin {
  background-color: $color-lightgray;
}

.container.content {
  padding-bottom: $footerheight;
}

.footer {
  color: $color-gray;
  position: absolute;
  bottom: 0;
  background-color: $color-white;
  width: 100%;

  > .container {
    background-color: $color-white;

    /* Set the fixed height of the footer here */
    height: $footerheight;
    padding-top: $footerheight / 2 - 4;
  }

  @include font-size(xs);

  .dropup {
    margin-top: -8px;

    button {
      color: $color-gray;
    }

    .active {
      color: $dropdown-link-active-color;
    }

    .btn-default {
      // color: $gray;
      font-size: 11px;
      background: transparent;

      // border-color: $gray-50;
      border-radius: 50px;

      .caret {
        margin-top: -2px;
      }
    }

    .dropdown-menu {
      font-size: 11px;
      padding: 0;

      // border-color: $gray-50;
      width: 100%;

      li + li {
        > a {
          padding-top: 0;
        }
      }

      a {
        // color: $gray;
        padding-left: 12px;
        padding-top: 12px;
        padding-bottom: 12px;

        &:hover {
          background-color: transparent;

          // color: $darkblue;

        }

        &.active {
          background-color: transparent;

          // color: $blue;

        }
      }

      min-width: auto;
    }
  }
}

.footer-links {
  a {
    margin-left: $grid-gutter-width;
    color: $color-black;

    @include font-size(xs);

    &:hover {
      color: $color-darkblue;
    }
  }

  a.language {
    margin-left: 0.5em;
  }

  a.language.selected {
    color: $color-active-blue;
  }
}

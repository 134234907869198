@use "../../node_modules/react-widgets/lib/scss/react-widgets" with (
  $font-path: "../../fonts",
  $font-size: 18px, // copied from _variables.scss
  $text-color: $color-black,
  $input-color: $color-black,
  $input-focus-color: $color-lightblue,
  $state-bg-hover: $color-lightblue,
  $input-bg: $color-lightblue,
  $input-bg-disabled: $color-lightgray,
  $input-border: $color-lightgray,
  $input-border-width: 0,
  $input-border-radius: 0,
);

.card-edit form {
  // structure copied from _forms.scss

  .rw-widget-container {
    border-width: 0 0 2px 0;
    border-color: transparent;
  }

  .rw-state-focus .rw-widget-container {
    border-color: $color-darkblue;
  }

  .error .rw-widget-container {
    border-color: $color-red;
  }
}

.rw-widget.rw-state-disabled {
  input.rw-input[disabled] {
    color: $color-darkgray;
  }
}

/* ==========================================================================
   Bootstrap Overrides
   ========================================================================== */
$font-family-sans-serif: Ubuntu, "Helvetica Neue", Helvetica, Arial, sans-serif;
$navbar-default-link-color: $color-black;
$navbar-default-link-hover-color: $color-blue;
$navbar-default-link-active-color: $color-darkblue;
$navbar-default-link-active-bg: transparent;
$dropdown-link-color: $color-gray;
$dropdown-link-hover-color: $color-darkblue;
$dropdown-link-active-color: $color-blue;
$breadcrumb-bg: transparent;
$breadcrumb-separator: "i";
$breadcrumb-color: $color-gray;

$btn-border-radius-base: 50px;
$btn-border-radius-large: 50px;
$btn-border-radius-small: 50px;

$btn-font-weight: 400;

$btn-default-color: $color-white;
$btn-default-bg: $color-gray;
$btn-default-border: transparent;

$btn-primary-color: $color-white;
$btn-primary-bg: $color-blue;
$btn-primary-border: transparent;

$alert-padding: 10px 0;
$alert-border-radius: none;
$alert-link-font-weight: 300;

$alert-success-bg: transparent;
$alert-success-text: $color-darkblue;
$alert-success-border: transparent;

$alert-info-bg: transparent;
$alert-info-text: $color-black;
$alert-info-border: transparent;

$alert-warning-bg: transparent;
$alert-warning-text: $color-blue;
$alert-warning-border: transparent;

$alert-danger-bg: transparent;
$alert-danger-text: $color-red;
$alert-danger-border: transparent;

$input-bg: $color-lightblue;
$input-bg-disabled: $color-lightgray;
$input-color: $color-black;
$input-border: transparent;
$input-border-radius: 0;
$input-border-radius-large: 0;
$input-border-radius-small: 0;
$input-border-focus: $color-darkblue;
$input-color-placeholder: $input-color;
$input-height-base: 46px;

$tooltip-color: $color-black;
$tooltip-bg: $color-lightyellow;
$tooltip-opacity: 1;
$tooltip-arrow-width: 5px;

$border-radius-base: 0;

$table-cell-padding: 15px 8px;
$table-bg-accent: $color-white;
$table-bg-hover: $color-lightgray;
$table-bg-active: $color-lightblue;
$table-border-color: $color-lightgray;

/* ==========================================================================
   WeWash
   ========================================================================== */
$font-size: 18px;
$line-height: 1.5;
$icon-size: 20px;

@mixin font-size($size: md) {
  @if $size == xs {
    font-size: $font-size / 1.63;
    line-height: $line-height / 1.25;
    font-weight: 300;
  }
  @else if $size == sm {
    font-size: $font-size / 1.28571;
    line-height: $line-height;
    font-weight: 100;
  }
  @else if $size == md {
    font-size: $font-size;
    line-height: $line-height * 0.75;
    font-weight: 300;
  }
  @else if $size == lg {
    font-size: $font-size * 1.33;
    line-height: $line-height * 0.75;
    font-weight: 300;
  }
  @else if $size == xl {
    font-size: $font-size * 2;
    line-height: $line-height * 0.5;
    font-weight: 300;
  }
  @else if $size == xxl {
    font-size: $font-size * 3;
    line-height: 1;
    font-weight: 300;
  }
  @else {
    font-size: $font-size;
    line-height: $line-height;
    font-weight: 300;
  }
}

@mixin trans($length: normal) {
  @if $length == short {
    transition: all .1s ease;
  }
  @else if $length == normal {
    transition: all .3s ease;
  }
  @else if $length == long {
    transition: all .6s ease;
  }
}

/* Header
   ========================================================================== */
$headerheight: 120px;

/* Footer
   ========================================================================== */
$footerheight: 60px;
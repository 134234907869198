.download-prices-section {
  margin-top: 5em;
  margin-bottom: 2em;
}

textarea {
  resize: none;
}

.order-postit {
  padding-bottom: 2em;

  .form-group {
    margin-right: 0;
  }

  .confirmation-text {
    margin-top: 5em;
    margin-bottom: 5em;
  }

  button {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.object-small-stats {
  // position: absolute;
  left: $grid-gutter-width;
  bottom: $card-footer-height + 20px;

  .stat-element {
    margin-bottom: .5em;

    .icon {
      color: $color-gray-25;
    }

    .icon-label {
      margin-left: 1em;
      color: $color-gray-25;
    }
  }
}

.icon-wew-calendar-euro-large {

  position: relative;

  .symbol {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2em;
    color: white;
    font-style: normal;
    font-weight: 500;
  }

  &.icon-md .symbol {
    font-size: 1.5em;
  }

  &.icon-sm .symbol {
    font-size: 1em;
  }
}

.serialnumbers-filter-input {
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;
}

.spinner-loading {
  display: inline-block;
  margin-right: 0.5em;
  border: 0.2em solid $color-lightgray;
  border-top: 0.2em solid $color-darkblue;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation: spin-loading 2s linear infinite;
}

@keyframes spin-loading {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

a.action-button {
  width: 100%;
  color: $color-gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border: 1px solid $color-gray-25;
  padding: 1em 0.5em;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  transition: all .3s ease-in-out;
  min-height: 9em;

  &:hover {
    box-shadow: $color-darkgray 0 4px 8px;
    color: $color-darkblue;
  }

  div.action-icon {
    width: 48px;
    height: 32px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .action-icon.money-change {
    background-image: url("/img/icons/money-check-euro-pen-solid_grey.png");
  }

  &:hover .action-icon.money-change {
    background-image: url("/img/icons/money-check-euro-pen-solid_blue.png");
  }

  .action-icon.discount {
    background-image: url("/img/icons/rectangle-percent-solid_grey.png");
  }

  &:hover .action-icon.discount {
    background-image: url("/img/icons/rectangle-percent-solid_blue.png");
  }


}

.mb-2 {
  margin-bottom: .5em;
}

.mt-2 {
  margin-top: .5em;
}

.ml-2 {
  margin-left: .5em;
}

.mr-2 {
  margin-right: .5em;
}

.timeline-connector {
  margin: -5px auto;
  border-left: 1px solid $color-gray-25;
  width: 0;
  height: 3em;
}

.timeline-box {
  border: 1px solid $color-gray-25;
  border-radius: 10px;
  padding: 20px;
  margin: 10px -20px;
  display: block;
  position: relative;

  .top-text {
    top: -10px;
    left: 15px;
    position: absolute;
    background: $color-white;
    padding: 0 5px;
    color: $color-darkblue;
  }

  .top-align {
    top: -10px;
  }
  .bottom-align {
    bottom: -10px;
  }
  .dot-holder {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    color: $color-gray-25;
  }

  .dot-text {
    position: absolute;
    left: 0;
    padding: 0 0.3em;
    margin-left: 50%;
    transform: translate(10px, 0);
    background: $color-white;
  }

}

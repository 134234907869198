html {
}

body {
  background: $color-lightgray;
  background-size: cover;
}

::selection {
  background-color: $color-blue;
  color: $color-white;
}

a {
  color: $color-black;

  @include trans;

  .icon {
    @include trans;
  }

  &.no-hover:hover {
    text-decoration: none;
  }

  &:not(.no-hover):hover {
    color: $color-blue;
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  &.icon {
    color: $color-blue;

    &:hover, &:active, &:focus {
      color: $color-darkblue;
      text-decoration: none;
    }
  }

  &.text-link {
    color: $color-blue;

    &:hover, &:active, &:focus {
      color: $color-darkblue;
      text-decoration: none;
    }
  }
}

hr {
  height: 2px;
  border: none;
  background-color: $color-gray;
  margin-top: $grid-gutter-width / 2;
  margin-bottom: $grid-gutter-width / 2;

  &.border-top {
    height: 0;
    margin-top: $grid-gutter-width / 4;
    margin-bottom: $grid-gutter-width / 4;
  }
}

small {
  @include font-size(xs);
}

.content-inner {
  margin: ($grid-gutter-width - 6px) 0 $grid-gutter-width;
}

.site-wrapper {
  min-height: 100vh;
}

.header {
  display: inline-block;
  background-color: $color-lightgray;
}

.anchor {
  display: block;
  position: relative;
  top: -$headerheight - $grid-gutter-width;
  visibility: hidden;
}

.divider {
  height: 3px;
  width: 100px;
  margin-top: 23px;
  background-color: $color-gray-25;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: $grid-gutter-width;
  border: none;
  text-align: left;
}

@media (min-width: $screen-lg-min) {
  .no-breadcrumb {
    .site-wrapper {
      min-height: calc(100vh - #{$headerheight} + 40px);
    }

    .content {
      margin-top: $headerheight - 40px;
    }
  }

  .site-wrapper {
    min-height: calc(100vh - #{$headerheight});
  }

  .header {
    position: fixed;
    top: 0;
    min-width: 100%;
    z-index: 2;
  }

  .content {
    margin-top: $headerheight;
  }

  .content-inner {
    margin: $grid-gutter-width 0;
  }

  .info:only-child {
    position: fixed;
    width: 270px;
  }
}

@media (max-height: 507px) {
  .info {
    position: static;
    width: auto;
  }
}

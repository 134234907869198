.navigation {
  background-color: $color-white;

  .container {
    background-color: $color-white;
  }

  .navbar {
    border: none;
    background-color: transparent;
    padding-top: $grid-gutter-width / 2;
    padding-bottom: $grid-gutter-width / 2;
    margin: 0;
  }

  .navbar-brand {
    transition: none;
    display: block;
    width: 440px;
    height: 69px;
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .navbar-mark-admin {
    transition: none;
    display: block;
    width: 150px;
    height: 29px;
    margin-top: 10px;
    margin-left: 380px;
  }
}

.svg .navigation .navbar-brand {
  background: url("../img/logos/logo-partnerboard.svg") no-repeat left center;
  background-size: contain;
}

.navigation .navbar-brand {
  background: url("../img/logos/logo-partnerboard.png") no-repeat left center;
  background-size: contain;
}

.svg .navigation .navbar-mark-admin {
  background: url("../img/logos/mark-admin.svg") no-repeat left center;
  background-size: contain;
}

.navigation .navbar-mark-admin {
  background: url("../img/logos/mark-admin.png") no-repeat left center;
  background-size: contain;
}

.navigation .navbar-brand.generic-brand {
  background: url("../img/logos/wewash_logo_200px.png") no-repeat left center;
  background-size: contain;
}

.address {
  background-color: $color-lightgray;
  padding: $grid-gutter-width / 2;
  margin-bottom: $grid-gutter-width / 1.5;
}

.address-footer {
  margin-top: $grid-gutter-width / 2;
  border-top: 2px solid $color-gray;
  padding-top: $grid-gutter-width / 2;
  text-align: right;

  > * {
    display: inline;
  }

  > * + * {
    margin-left: $grid-gutter-width / 2;
  }
}

.address-wrapper.active {
  .address-footer {
    border-color: $color-blue;
  }

  .btn-default {
    @extend .btn-primary;
  }
}
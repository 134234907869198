$color-white: rgba(255, 255, 255, 1);
$color-lightblue: rgba(242, 248, 251, 1);
$color-blue: rgba(0, 179, 230, 1);
$color-darkblue: rgba(0, 109, 182, 1);
$color-lightgray: rgba(245, 246, 247, 1);
$color-darkgray: rgba(104, 113, 117, 1);
$color-gray: rgba(157, 157, 157, 1);
$color-gray-25: lighten($color-darkgray, 40);
$color-black: rgba(59, 59, 58, 1);
$color-red: rgba(255, 67, 67, 1);
$color-darkred: darken($color-red, 20);
$color-lightyellow: rgba(254, 241, 148, 1);
$color-yellow: rgba(255, 228, 0, 1);
$color-green: rgba(39, 167, 48, 1);
$color-light-green: rgba(130, 255, 156, 1);

$color-hover-blue: mix($color-darkblue, $color-blue, 30%);
$color-active-blue: mix($color-darkblue, $color-blue, 60%);

.color-blue {
  color: $color-blue;
}

.bg-color-blue {
  background-color: $color-blue;
}

.color-darkblue {
  color: $color-darkblue;
}

.message, .postit {
  [class*="headline"] {
    color: $color-black;
    margin-bottom: $grid-gutter-width - 6px;

    & span {
      color: $color-black;
    }
  }

  > *:last-child {
    margin-bottom: 0;
  }

  a {
    color: $color-black;
    text-decoration: underline;

    &:hover {
      color: $color-darkblue;
    }
  }
}

.message {
  background-color: $color-lightyellow;
  padding: $grid-gutter-width;
  width: 100%;
  position: relative;
  min-height: 100px;
  z-index: 0;
  margin-top: $grid-gutter-width;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: -20px;
    border: 10px solid black;
    border-color: transparent $color-yellow $color-yellow transparent;
    transform-origin: 0 0;
  }
}

.postit {
  background-color: $color-lightyellow;
  padding: $grid-gutter-width;
  width: 100%;
  position: relative;
  min-height: 100px;
  z-index: 0;
  margin-bottom: $grid-gutter-width;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    border-style: solid;
    border-width: 0 0 20px 20px;
    border-color: transparent transparent $color-yellow $color-lightgray;
  }
}

.debug {
  padding: $grid-gutter-width;
  background-color: green;
  font-family: courier;
  color: $color-white;

  .headline1 {
    color: $color-white;
    margin-bottom: $grid-gutter-width;
  }

  font-weight: 400;

  &:before {
    display: none;
  }
}
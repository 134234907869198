.btn {
  margin-bottom: 3px;
  @include button-size(10px, 40px, $font-size-base, $line-height-base, $btn-border-radius-base);

  @include trans(normal);

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      outline: none;
    }
  }

  &:active,
  &.active {
    box-shadow: none;
  }

  &.btn-primary {
    &:focus {
      background-color: $color-blue;
      border-color: $color-blue;
    }

    &:hover {
      background-color: $color-hover-blue;
      border-color: $color-hover-blue;
    }

    &:active,
    &.active {
      background-color: $color-active-blue;
      border-color: $color-active-blue;
    }
  }
}

.btn-sm {
  @include button-size(10px, 25px, $font-size-base, $line-height-base, 0);
}

.btn.btn-action.btn-top {
  padding: 0;
  text-align: center;
  background-color: transparent;
  border: 0;

  &:before {
    padding-right: 0;
  }
}

.btn.btn-action, .btn.icon {
  border-radius: 0;
  color: $color-blue;

  &:hover {
    text-decoration: none;
    color: $color-darkblue;
  }

  &:active, &:focus {
    text-decoration: none;
    color: $color-blue;
  }

  padding-left: 0;
  padding-right: 0;

  @include font-size(xs);

  &:before {
    padding-right: 10px;
    vertical-align: middle;
  }

  @include icon-size(sm);
}

.btn-close {
  @include icon;

  @extend .icon-wew-close;
}

.btn-edit {
  @include icon;

  @extend .icon-wew-edit;
}

.btn-add {
  @include icon;

  @extend .icon-wew-circle-add;

  &:before {
    margin-right: 0.4em;
    font-size: 10px;
  }
}

.icon-for-button{
  font-size: $icon-size;
  margin-right: 3px;
}

.breadcrumb-wrapper {
  @include font-size(sm);

  line-height: 20px;

  .breadcrumb, .backlink {
    margin-bottom: 0;
    padding-left: 0px;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;

    a {
      color: $color-black;

      &.active {
        color: $color-darkblue;
      }

      &:hover {
        color: $color-blue;
      }
    }

    &.active a {
      color: $color-blue;
    }
  }

  a.backlink {
    float: left;
    color: $color-black;

    .icon {
      color: $color-black;
    }

    &:hover {
      color: $color-blue;

      .icon {
        color: $color-blue;
      }
    }

    > * {
      margin-right: $grid-gutter-width / 4;
    }
  }
}

.breadcrumb > li + li {
  @include icon;

  @include icon-size;
}

.breadcrumb > li:last-child > a {
  color: $color-darkblue;
}

.tooltip {
  @include font-size(sm);
}

.tooltip-inner {
  padding: ($grid-gutter-width / 8) ($grid-gutter-width / 3);

  @include level(1);
}

// .tooltip-arrow {
//   display: none;
// }
/* ==========================================================================
   Card General
   ========================================================================== */
$card-footer-height: 60px;
.card {
  a.icon {
    min-width: 20px;
    min-height: 20px;
    display: inline-block;
  }

  position: relative;
  background-color: $color-white;
  padding: 0;
  display: inline-block;
  min-height: 300px - $grid-gutter-width;
  min-width: 270px;
  width: 100%;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-top: 5px solid $color-darkblue;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  margin-bottom: $grid-gutter-width - 5px;

  .card-content {
    padding: ($grid-gutter-width - 5px) $grid-gutter-width $grid-gutter-width $grid-gutter-width;
    min-height: 300px - $card-footer-height - $grid-gutter-width - 5px;

    .icon-md,
    .icon-lg,
    .icon-xl {
      display: block;
      text-align: center;
      margin-top: $grid-gutter-width;
    }

    .icon-lg {
      .font-xxl {
        padding-left: 10px;
        bottom: 0;
        margin-bottom: 10px;
        vertical-align: middle;
      }
    }

    .mt-10 {
      .icon-md,
      .icon-lg,
      .icon-xl {
        margin-top: $grid-gutter-width / 1.5;
      }

      .icon-label-md,
      .icon-label-lg {
        margin-bottom: $grid-gutter-width / 1.5;
      }
    }

    .mt-20 {
      .icon-md,
      .icon-lg,
      .icon-xl {
        margin-top: $grid-gutter-width / 3;
      }

      .icon-label-md,
      .icon-label-lg {
        margin-bottom: $grid-gutter-width / 6;
      }
    }

    .icon-label-lg {
      @include font-size(xl);

      margin-top: $grid-gutter-width / 2;
    }

    .icon-label-md {
      @include font-size(lg);

      margin-top: $grid-gutter-width / 6;
    }

    .icon-label {
      text-align: center;
      color: $color-darkblue;
    }
  }

  &.card-multi-line .card-content {
    min-height: 260px - $card-footer-height - $grid-gutter-width - 5px;
  }
  &.card-no-min-height .card-content {
    min-height: 0;
  }

  .card-footer {
    height: $card-footer-height;
    border-top: 1px solid $color-lightgray;
    margin-left: $grid-gutter-width;
    margin-right: $grid-gutter-width;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  .card-footer-item-wrapper {
    height: $card-footer-height - 12;

    .selected {
      background-color: $color-lightblue;
      color: $color-black !important;
    }

    .card-footer-item {
      display: block;
      border-top: 1px solid $color-lightgray;
      padding: 12px $grid-gutter-width;
      color: $color-gray;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      .card-footer-icon {
        display: inline-block;
        width: 10%;
      }

      .title {
        margin-left: 10px;
      }

      a {
        color: $color-gray;
      }

      &:hover {
        background-color: $color-lightgray;
        color: $color-black;

        a {
          color: $color-black;
        }
      }
    }
  }

  .card-footer-left {
    color: $color-gray;

    .icon {
      color: $color-gray;
    }

    a.icon {
      color: $color-blue;

      &:hover {
        color: $color-darkblue;
      }
    }

    > * {
      display: inline;
    }

    > * + * {
      margin-left: $grid-gutter-width / 2;
    }
  }

  .card-footer-left,
  .card-footer-right {
    @include font-size(sm);

    float: left;
    line-height: 20px;
    height: 20px;
    width: auto;
  }

  .card-footer-right {
    float: right;

    > * {
      display: inline;
    }

    > * + * {
      margin-left: $grid-gutter-width / 2;
    }
  }

  .card-desc {
    padding-right: $grid-gutter-width + 10px;
    text-align: justify;
    margin-bottom: .5em;
  }

  &.card-alert {
    border-color: $color-red;

    .card-content {
      [class*="headline"],
      [class*="headline"] span,
      > .icon {
        color: $color-red;
      }

      > a.icon:hover {
        color: $color-darkred;
      }
    }

    .card-footer-left a.icon,
    .card-footer-right a.icon {
      color: $color-red;

      &:hover {
        color: $color-darkred;
      }
    }
  }

  &.card-info {
    border-color: $color-lightyellow;
  }

  &.card-add {
    border-color: $color-gray;
  }

  .btn-action.btn-top {
    position: absolute;
    right: $grid-gutter-width;
    top: $grid-gutter-width;
  }
}

.card-primary {
  border-image: linear-gradient(to right, $color-blue 0%, $color-blue 20%, $color-darkblue 80%, $color-darkblue 100%);
  border-image-slice: 1;
}

/* ==========================================================================
   Hover Mode on Clickbox
   ========================================================================== */
// .clickbox {
//   cursor: pointer;
// }

.clickbox.level-0 {
  &:hover {
    @include level(1);
  }

  &.clicked {
    @include level;
  }
}

.clickbox.level-1 {
  &:hover {
    @include level(2);
  }

  &.clicked {
    @include level;
  }
}

.clickbox.level-2 {
  &:hover {
    @include level(3);
  }

  &.clicked {
    @include level;
  }
}

.clickbox.level-3 {
  &:hover {
    @include level(4);
  }

  &.clicked {
    @include level;
  }
}

.clickbox.level-4 {
  &:hover {
    @include level(5);
  }

  &.clicked {
    @include level;
  }
}

/* ==========================================================================
   Edit Mode
   ========================================================================== */
.card-edit {
  &.level-1 {
    @include level(2);
  }

  &.level-2 {
    @include level(3);
  }

  &.level-3 {
    @include level(4);
  }

  &.level-4 {
    @include level(5);
  }
}
